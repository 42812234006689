import React, {useCallback, useEffect} from "react";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";

export type CheckboxGroupProps = {
    labels: string[],
    values: string[],
    validValues: string[],
    setValue: (v: string[]) => {}
    isMulti?: boolean
}
export const useCheckboxGroup = ({values, setValue, isMulti, validValues}: Omit<CheckboxGroupProps, 'labels'>) => {

    useEffect(() => {
        const newValues = values.filter(v => validValues.includes(v));
        if (newValues.length !== values.length) {
            setValue(newValues);
        }
    }, [validValues, values, setValue]);

    const handleClick = useCallback((label: string) => {
        const newValue = values.filter((v) => v !== label);
        if (newValue.length === values.length) {
            if (isMulti) {
                newValue.push(label);
            } else {
                setValue([label]);
                return;
            }
        }
        setValue(newValue);
    }, [values, setValue, isMulti]);

    const isChecked = useCallback((label: string) => values.includes(label), [values])

    const isValid = useCallback((label: string) => validValues.includes(label), [validValues])
    return {
        handleClick, isChecked, isValid
    }
}
export const CheckboxGroup = ({labels, values, setValue, isMulti, validValues}: CheckboxGroupProps) => {
    const {handleClick, isChecked, isValid} = useCheckboxGroup({values, setValue, isMulti, validValues})
    return (
        <FormGroup>
            {labels.map((label) => <FormControlLabel
                key={label}
                control={
                    <Checkbox
                        checked={isChecked(label)}
                        disabled={!isValid(label)}
                        onClick={isValid(label) ? handleClick.bind(null, label) : undefined}
                    />
                }
                label={label}
                disabled={!isValid(label)}
            />)}
        </FormGroup>
    )
}
