import {Typography} from "@mui/material";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '32px',
            lineHeight: '101.9%',
            [theme.breakpoints.up('sm')]: {
                fontSize: '40px',
            },
        },
    })
);

export const Title = ({children}: {children: any}) => {
    const classes = useStyles();
    return (<Typography component="h2" className={classes.title}>{children}</Typography>)
}
