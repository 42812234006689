import {useQuery} from "@apollo/client";
import {REVIEWS_QUERY} from "./Reviews.gql";
import {ReviewsQuery, ReviewsQueryVariables} from "../../../../generated/graphql";
import React from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {ButtonBase, Container, Grid, Stack, Theme} from "@mui/material";
import {Title} from "../Title";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { ReactComponent as Reviewer } from '../../../../assets/reviewer.svg';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                paddingLeft: 0,
                paddingRight: 0,
                marginTop: '100px',
            }
        },
        comment: {
            border: '2px solid #000000',
            filter: 'drop-shadow(0px 4px 78px rgba(0, 0, 0, 0.1))',
            borderRadius: '24px',
            overflow: 'hidden',
            overflowY: 'auto',
            height: 'calc(100vh - 230px)',
            [theme.breakpoints.up('md')]: {
                height: 241,
                padding: '40px 60px',
            }
        },
        commentBox: {
            [theme.breakpoints.up('md')]: {
                width: '80%',
            }
        },
        stepper: {
            border: '2px solid #000',
            borderRadius: '50%',
            margin: '0 5px',
            width: 16,
            height: 16,
        },
        button: {
            boxSizing: 'border-box',
            width: '42px',
            height: '42px',
            border: '2px solid #000000',
            borderRadius: '50%',
            '&[disabled]': {
                opacity: 0.2,
                cursor: 'not-allowed',
            },
            [theme.breakpoints.up('md')]:{
                width: 60,
                height: 60,
            }
        },
        buttons: {
            [theme.breakpoints.up('md')]: {
                position: 'absolute',
                top: '290px',
                justifyContent: 'space-between',
                width: '100%',
                left: 0,
            }
        },
        active: {
            background: 'black'
        },
        reviewText: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '125.4%',
        }
    })
);

export const Reviews = () => {
    const classes = useStyles();
    const { data, loading } = useQuery<ReviewsQuery,ReviewsQueryVariables>(REVIEWS_QUERY);
    const reviews = data?.reviews;

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = reviews?.length || 0;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = () => {
        // setActiveStep(step);
    };

    if(!reviews) {
        return loading ? <div>Загрузка...</div> : null;
    }

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3} component={Box} py={5} px={{xs: 1, md:0}}>
                <Grid item xs={6} mb={{md: 4}}>
                    <Title>Отзывы</Title>
                </Grid>
                <Grid item xs={6} justifyContent={'flex-end'}>
                    <Stack direction='row' spacing={1} justifyContent={'flex-end'} className={classes.buttons}>
                        <ButtonBase
                            className={classes.button}
                            onClick={handleBack}
                            disabled={activeStep === 0}
                        >
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight fontSize={'large'} />
                            ) : (
                                <KeyboardArrowLeft fontSize={'large'} />
                            )}
                        </ButtonBase>
                        <ButtonBase
                            className={classes.button}
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                        >
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft fontSize={'large'} />
                            ) : (
                                <KeyboardArrowRight fontSize={'large'} />
                            )}
                        </ButtonBase>
                    </Stack>

                </Grid>
                <Grid item xs={12} alignItems='center' flexDirection='column' display={{md: 'flex'}} >
                    <Box className={classes.commentBox}>
                        <AutoPlaySwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                            autoPlay={false}
                        >
                            {reviews?.map((step, index) => (
                                <div key={step.id}>
                                    {Math.abs(activeStep - index) <= 2 ? (
                                        <Box
                                            className={classes.comment}
                                            p={2}
                                        >
                                            <Typography paddingBottom={2}>
                                                <Title>
                                                    <Reviewer style={{marginBottom: '-6px'}} /> {step.name}
                                                </Title>
                                            </Typography>
                                            <Box>
                                                <Typography fontSize={18}>{step.text}</Typography>
                                            </Box>
                                        </Box>
                                    ) : null}
                                </div>
                            ))}
                        </AutoPlaySwipeableViews>
                    </Box>
                    <Box mt={'40px'}>
                        <Stack direction='row' justifyContent='center'>
                            {reviews.map((step, index) => (
                                <ButtonBase
                                    key={step.id}
                                    className={[index === activeStep ? classes.active : '', classes.stepper].join(' ')}
                                    onClick={setActiveStep.bind(null, index)}
                                ></ButtonBase>
                            ))}
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}
