import makeStyles from '@mui/styles/makeStyles';
import {ButtonBase, Container, Stack, Typography} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import React from "react";
import {ReactComponent as Logo} from "../../assets/logo.svg";
import {TelegramButton} from "../../pages/Home/components/TelegramButton";
import {MailButton} from "../../pages/Home/components/MailButton";

export const useStyles = makeStyles(() =>
    createStyles({
        footer: {
            background: 'black',
            color: 'white',
        },
        container: {
            padding: '0',
        },
        title: {
            display: 'inline-block',
            width: '140px',
            height: '46px',
            fontFamily: 'Orelega One',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '24px',
            lineHeight: '96.03%',
            marginLeft: '15px',
            color: 'white',
        },
        outlineButton: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '15px',
            lineHeight: '26px',
            height: '52px',
            border: '2px solid #ffffff',
            borderRadius: '16px',
            color: '#ffffff',
            padding: '22px',
        },
    }),
);

export function Footer() {
    const classes = useStyles();
    return <div className={classes.footer}>
        <Container className={classes.container}>
            <Stack
                direction={{xs: 'column', sm: 'row'}}
                justifyContent={{xs: 'center', sm: 'space-between'}}
                spacing={3}
                py={{xs:3, md: 2}}
                px={{xs:3, lg: 0}}
            >
                <Stack direction='row' justifyContent='center'>
                    <Logo fill='white' />
                    <Typography variant="h6" className={classes.title}>
                        Шульгина <br/> Кристина
                    </Typography>
                </Stack>
                <Stack direction={{xs: 'column', sm: 'row'}}  spacing={3}>
                    <Stack direction='row' justifyContent='center'>
                        <ButtonBase className={classes.outlineButton} component={'a'} href='#request'>ОСТАВИТЬ ЗАЯВКУ</ButtonBase>
                    </Stack>
                    <Stack spacing={3} direction='row' justifyContent='center'>
                        <TelegramButton inverse />
                        <MailButton inverse />
                    </Stack>
                </Stack>
            </Stack>
        </Container>
    </div>;
}
