import React from "react";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    FilledInput,
    FormControl,
    InputAdornment,
    InputLabel,
    TextField
} from "@mui/material";
import {useFormik} from "formik";
import Autocomplete from '@mui/material/Autocomplete';
import {useMutation, useQuery} from "@apollo/client";
import {CREATE_EVENTS, CREATE_LESSON_INFO} from "./CreateLessonForm.gql";
import {
    CreateEventsMutation, CreateEventsMutationVariables,
    CreateLessonInfoQuery,
    CreateLessonInfoQueryVariables, Learner, Lesson,
} from "../../generated/graphql";
import moment from "moment";
import { TimePicker, DatePicker } from "@mui/lab";

type FormType = {
    title: string,
    lesson: Lesson | null,
    from: string,
    dateTo: string | null,
    duration: number,
    freeTimeDuration: number,
    cost: number,
    user: Learner | null,
}

export const CreateLessonForm = ({initValues, close}:{initValues?: FormType, close: () => void}) => {
    const formik = useFormik<FormType>({
        initialValues: initValues || {
            title: '',
            lesson: null,
            from: '',
            dateTo: null,
            duration: 0,
            freeTimeDuration: 0,
            cost: 0,
            user: null,
        },
        onSubmit: async values => {
            if(!values.user?.id || !values.lesson?.id ) {
                alert('не заполнены обязательные поля');
                return;
            }
            await createEvents({ variables: {
                event: {
                    title: values.title,
                    learnerId: parseInt(values.user.id.split(' ')[0]),
                    lessonId: values.lesson.id,
                    start: moment(values.from, 'DD.MM.YYYY HH:mm').toISOString(),
                    duration: values.duration,
                    freeTimeDuration: values.freeTimeDuration,
                    end: moment(values.dateTo, 'DD.MM.YYYY').toISOString(),
                    cost: values.cost,
                }
            }})
            close();
        },
    });

    const {data} = useQuery<CreateLessonInfoQuery, CreateLessonInfoQueryVariables>(CREATE_LESSON_INFO);
    const [createEvents] = useMutation<CreateEventsMutation, CreateEventsMutationVariables>(CREATE_EVENTS, {refetchQueries: ['getCalendarEvents']});
    const userList = data?.learners;
    const lessonList = data?.lessons;
    return (
        <Card variant="outlined">
            <form onSubmit={formik.handleSubmit}>
                <CardContent>
                    <p>
                        <TextField label="Название занятия" name='title' onChange={formik.handleChange} value={formik.values.title}/>
                    </p>
                    <p>
                        <Autocomplete
                            id="combo-box-demo"
                            options={lessonList ?? []}
                            getOptionLabel={(option) => option.name ?? ''}
                            value={formik.values.lesson}
                            onChange={(e, option) => {
                                formik.setFieldValue("lesson", option)
                            }}
                            renderInput={(params) => <TextField {...params} label="Предмет" variant="outlined" />}
                        />
                    </p>
                    <p>
                        <div>
                            <InputLabel shrink>Начало</InputLabel>
                            <TimePicker
                                inputFormat='DD.MM.YYYY HH:mm'
                                value={formik.values.from ? moment(formik.values.from, 'DD.MM.YYYY HH:mm') : ''}
                                onChange={(_: unknown, str: unknown) => formik.setFieldValue("from", str)}
                                ampm={false}
                                renderInput={(props: unknown) => <input name='from' onBlur={() => formik.handleBlur("from")}/>}
                            />
                        </div>
                    </p>
                    <p>
                        <div>
                            <InputLabel shrink>Повторять до</InputLabel>
                            <DatePicker
                                inputFormat='DD.MM.YYYY'
                                value={formik.values.dateTo && moment(formik.values.dateTo, 'DD.MM.YYYY')}
                                onChange={(_: unknown, str: unknown) => formik.setFieldValue("dateTo", str)}
                                renderInput={(props: unknown) => <input name='dateTo' onBlur={() => formik.handleBlur("dateTo")}/>}
                            />
                        </div>
                    </p>
                    <p>
                        <InputLabel shrink>Длительность занятия</InputLabel>
                        <FormControl variant="outlined">
                            <FilledInput
                                type="number"
                                value={formik.values.duration}
                                onChange={formik.handleChange}
                                endAdornment={<InputAdornment position="end">минут</InputAdornment>}
                                aria-describedby="filled-duration"
                                name="duration"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                    </p>
                    <p>
                        <InputLabel shrink>Длительность перерыва</InputLabel>
                        <FormControl variant="outlined">
                            <FilledInput
                                type="number"
                                value={formik.values.freeTimeDuration}
                                onChange={formik.handleChange}
                                endAdornment={<InputAdornment position="end">минут</InputAdornment>}
                                aria-describedby="filled-free-Time-Duration"
                                name="freeTimeDuration"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                    </p>
                    <p>
                        <Autocomplete
                            options={userList ?? []}
                            getOptionLabel={(option) => option.learner ?? ''}
                            value={formik.values.user}
                            onChange={(e, option) => {
                                formik.setFieldValue("user", option)
                            }}
                            renderInput={(params) => <TextField {...params} label="Ученик" variant="outlined" />}
                        />
                    </p>
                    <p>
                        <InputLabel shrink>Стоимость занятия</InputLabel>
                        <FormControl variant="outlined">
                            <FilledInput
                                type="number"
                                value={formik.values.cost}
                                onChange={formik.handleChange}
                                endAdornment={<InputAdornment position="end">руб.</InputAdornment>}
                                aria-describedby="filled-cost"
                                name="cost"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                    </p>
                </CardContent>
                <CardActions>
                    <Button size="small" onClick={close}>Отмена</Button>
                    <Button size="small" color="primary" type='submit'>Запланировать</Button>
                </CardActions>
            </form>
        </Card>
    );
}
