import { gql } from "@apollo/client";

export const REQUEST_MUTATION = gql`
    mutation Request($request: RequestInput!) {
        addRequest(request: $request) {
            id
            name
        }
    }
`
