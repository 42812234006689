import makeStyles from "@mui/styles/makeStyles";
import {Theme} from "@mui/material";
import createStyles from "@mui/styles/createStyles";

const infoBlock = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '13px',
  lineHeight: '110%',
  paddingLeft: '13px',
  paddingTop: '8px',

  borderRadius: '12px',
}
export const useHomeStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      marginTop: '37px',
      position: "relative",
      '& li::marker': {
        fontSize: 'xx-small',
      }
    },
    container: {
      padding: '0 24px',
      overflow: 'hidden',
      flexGrow: 1,

      [theme.breakpoints.up('md')]: {
        padding: '0',
      },
    },
    contentBlock: {
      borderRadius: '24px',
      [theme.breakpoints.down('md')]: {
        paddingBottom: '80px',
        marginTop: '-50px',
      },
      [theme.breakpoints.up('md')]: {
        padding: '0 60px',
      },
    },
    container2: {
      padding: '0',
      flexGrow: 1,
      marginTop: '100px',
      zIndex: 2,
      [theme.breakpoints.down('md')]: {
        marginTop: '0',
      },
    },
    title: {
      display: 'inline-block',
      width: '140px',
      height: '46px',
      fontFamily: 'Orelega One',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '96.03%',
      marginLeft: '15px',
    },
    loginButton: {
      padding: '12px 0',
      border: '2px solid black',
      borderRadius: '12px',
      minWidth: '52px',
      height: '52px',
    },
    loginButtonName: {
      display: "none",
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '16px',
      lineHeight: '26px',
      textTransform: 'uppercase',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    descriptionText: {
      width: '342px',
      height: '164px',
      marginTop: '32px',

      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '40px',
      [theme.breakpoints.up('md')]: {
        fontSize: '60px',
        width: '550px',
        height: '244px',
      },
      lineHeight: '101.9%',
    },
    infoBlock: {
      width: '129px',
      height: '54px',
      background: '#FFEAF5',
      ...infoBlock,
      [theme.breakpoints.up('md')]: {
        fontSize: '14px',
        width: '174px',
        height: '77px',
        padding: '16px 24px',
        boxSizing: 'border-box',
      },
    },
    infoBlock1: {
      width: '142px',
      height: '54px',
      background: '#D3EDF3',
      ...infoBlock,
      [theme.breakpoints.up('md')]: {
        fontSize: '14px',
        width: '187px',
        height: '77px',
        padding: '16px 24px',
        boxSizing: 'border-box',
      },
    },
    infoBlock2: {
      width: '132px',
      height: '66px',
      background: '#FFFBE6',
      ...infoBlock,
      [theme.breakpoints.up('md')]: {
        fontSize: '14px',
        width: '242px',
        height: '77px',
        padding: '16px 24px',
        boxSizing: 'border-box',
      },
    },
    avatarBlock: {
      height: '268px',
      [theme.breakpoints.up('md')]: {
        height: '110px',
      }
    },
    avatar: {
      marginTop: '-15px',
      marginLeft: '30px',
      [theme.breakpoints.up('md')]: {
        marginTop: '-300px',
        marginLeft: '-110px',
        height: '690px',
        width: '690px',
        position: 'absolute',
      },
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    outlineButton: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '15px',
      lineHeight: '26px',

      width: '100%',
      height: '52px',
      border: '2px solid #000000',
      borderRadius: '16px',
      [theme.breakpoints.up('md')]: {
        width: 'auto',
        height: 'auto',
        padding: '13px 22px',
        fontSize: '16px',
      }
    },
    filledButton: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '15px',
      lineHeight: '26px',
      color: 'white',

      height: '52px',
      background: '#000000',
      borderRadius: '16px',
      [theme.breakpoints.up('md')]: {
        width: 'auto',
        height: 'auto',
        padding: '13px 22px',
        fontSize: '16px',
      }
    },
    snButtonsContainer: {
      [theme.breakpoints.up('md')]: {
        display: 'block',
      }
    },
    snButtonsWrapper: {
      [theme.breakpoints.up('md')]: {
        float: 'right',
        width: 104
      }
    },
    snButtons: {
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        top: 0,
      }
    },
    text: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '125.4%',
    }
  }),
);
