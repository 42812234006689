import React, {useState} from "react"
import {Box, ButtonBase, Fade, Modal,} from "@mui/material";
import {Telegram} from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import telegramProfile from "../../../assets/telegramProfile.png";

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            background: 'black',
            borderRadius: '50%',
            width: '52px',
            height: '52px',
            color: 'white',
        },
        inverseButton: {
            background: 'white',
            color: 'black',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    })
)

type TelegramButtonProps = { inverse?: boolean };
export const TelegramButton = ({inverse}: TelegramButtonProps) => {

    const [visibility, setVisibility] = useState(false);
    const showTelegram = () => {
        setVisibility(true)
    }
    const hideContact = () => {
        setVisibility(false)
    }

    const classes = useStyles();
    const inverseButtonStyle = [classes.button, classes.inverseButton].join(' ');
    return <>
        <ButtonBase className={inverse ? inverseButtonStyle : classes.button} onClick={showTelegram}>
            <Telegram fontSize={'large'} />
        </ButtonBase>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={visibility}
            onClose={hideContact}
            closeAfterTransition
            className={classes.modal}
        >
            <Fade in={visibility}>
                <Box>
                    <img src={telegramProfile} style={{
                        maxWidth: '100vw',
                        maxHeight: '1000px'
                    }} alt={'QR'}/>
                </Box>
            </Fade>
        </Modal>
    </>
}
