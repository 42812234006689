import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers';
import "moment/locale/ru";

import './App.css';
import { createTheme, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import {Home} from "./pages/Home/Home";
import {ApolloProvider} from '@apollo/client';
import {client} from "./core/client";
import {Lk} from "./pages/Lk/Lk";
import {ResetPassword} from "./pages/ResetPassword/ResetPassword";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        top: {
            minHeight: 'calc(100vh - 172px)',
        },
    }),
);

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
            light: '#000000',
            dark: '#000000',
        },
        divider: '#000000',
    },
    components: {
        MuiChip: {
          styleOverrides: {
              root: {
                  borderColor: '#000000',
                  fontFamily: 'Montserrat',
                  fontWeight: 600,
                  fontSize: '16px',
              },
              filled: {
                  backgroundColor: '#000000',
                  color: '#FFFFFF',
                  fontFamily: 'Montserrat',
                  fontWeight: 600,
                  fontSize: '16px',
              }
          }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    height: '52px',
                },
                notchedOutline: {
                    borderColor: '#000000'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                containedSizeLarge: {
                    height: '52px',
                    borderRadius: '16px',
                    fontFamily: 'Montserrat',
                    fontWeight: 800,
                    fontSize: '15px',
                    lineHeight: '26px',
                }
            }
        }
    },
});

function Content() {
    const classes = useStyles();
    return <div className={classes.top}>
        <Routes>
            <Route path="/lk/*" element={<Lk/>}/>
            <Route path="/reset/:token" element={<ResetPassword/>}/>
            <Route path="/" element={<Home/>}/>
        </Routes>
    </div>;
}

function App() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <ApolloProvider client={client}>
                        <Router>
                            <Content/>
                        </Router>
                    </ApolloProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
