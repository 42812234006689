import Box from "@mui/material/Box";
import {Button, Grid, Typography} from "@mui/material";
import React, {memo, useCallback} from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {useMutation} from "@apollo/client";
import {REQUEST_MUTATION} from "./Request.gql";
import {RequestMutation, RequestMutationVariables} from "../../../../generated/graphql";
import {RequestForm} from "./RequestForm";
import {FormValueType} from "./Request.constants";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '32px',
            lineHeight: '101.9%',
            [theme.breakpoints.up('md')]: {
                fontSize: '80px',
            },
        },
        sendAganButton: {
            width: '387px',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            }
        },
    })
);

export const Request = memo(({className}: {className:string}) => {
    const classes = useStyles();

    const [mutation, {data, reset, error, loading}] = useMutation<RequestMutation, RequestMutationVariables>(REQUEST_MUTATION);
    const onSubmit = useCallback(async (value: FormValueType) => {
        const {data: request} = await mutation({variables: {request: {
            ...value,
            ageRange: value.ageRange?.[0]?.split('-').map(Number)
        }}});
        localStorage.setItem(`request`, JSON.stringify(request?.addRequest));
    }, [mutation]);

    const requestData = data?.addRequest ?? JSON.parse(localStorage.getItem('request') ?? '{}') ?? {}

    const newRequest = useCallback(() => {
        localStorage.removeItem('request');
        reset();
    }, [reset])

    return (
        <Grid
            component={Box}
            px={3}
            className={className}
            style={{background: '#D3EDF3', paddingBottom: '40px'}}
            id='request'
        >
            <Box pt={5} pb={{xs: 0, md: 7}}>
                {requestData.id ? (
                    <>
                        <Typography className={classes.title}>Спасибо, {requestData.name}!</Typography>
                        <Box my='30px'>
                            <Typography>Заявка успешно отправлена. Ей присвоен номер [<b>{requestData.id}</b>].</Typography>
                            <Typography component={Box} mt={'20px'}>
                                Если вы хотите отправить ещё одну заявку, то нажмите кнопку ниже.
                            </Typography>
                        </Box>

                        <Button variant="contained" size='large' className={classes.sendAganButton} onClick={newRequest}>Создать ещё одну заявку</Button>
                    </>
                ) : (
                    <>
                        <Typography component="h2" className={classes.title}>Оставить заявку</Typography>
                        <RequestForm onSubmit={onSubmit} error={error} disabled={loading}/>
                    </>
                )}
            </Box>
        </Grid>
    )
});
