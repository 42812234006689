import {
    AppBar,
    Button,
    Container,
    Drawer,
    Fade,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Modal,
    Theme,
    Toolbar,
    Typography,
    Divider,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import React, {useState} from "react";
import 'react-big-calendar/lib/css/react-big-calendar.css'
import makeStyles from '@mui/styles/makeStyles';
import { CreateLessonForm } from "../../components/CreateLessonForm/CreateLessonForm";
import MenuIcon from '@mui/icons-material/Menu';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PeopleIcon from '@mui/icons-material/People';
import {Link as BaseLink, Route, Routes, useLocation} from "react-router-dom";
import {AccessTime} from "../teacher/AccessTime/AccessTime";
import {Schedule} from "../teacher/Schedule/Schedule";
import { Subjects } from "../teacher/Subjects/Subjects";
import {Learners} from "../teacher/Learners/Learners";
import { Materials } from "../teacher/Materials/Materials";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
    }),
);


const LeftMenu = () => {
    let { pathname } = useLocation();
    return (
        <div>
            <List>
                <ListItem component={BaseLink} to={pathname}>
                    <ListItemIcon><EventNoteIcon/></ListItemIcon>
                    <ListItemText primary='Расписание' />
                </ListItem>
                <ListItem component={BaseLink} to={`${pathname}/learners`}>
                    <ListItemIcon><PeopleIcon/></ListItemIcon>
                    <ListItemText primary='Ученики' />
                </ListItem>
                <ListItem component={BaseLink} to={`${pathname}/access-time`}>
                    <ListItemIcon><AccessTimeIcon/></ListItemIcon>
                    <ListItemText primary='Рабочие часы' />
                </ListItem>
                <ListItem component={BaseLink} to={`${pathname}/lessons`}>
                    <ListItemIcon><MenuBookIcon/></ListItemIcon>
                    <ListItemText primary='Предметы' />
                </ListItem>
            </List>
            <Divider />
            <ListItem button>
                <Button> Выход </Button>
            </ListItem>
        </div>
    )
}

export const Teacher = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [openMenu, setMenuOpen] = React.useState(false);

    const handleMenuOpen = () => {
        setMenuOpen(true);
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
    };

    let { pathname } = useLocation();

    return <>
        <AppBar position="static">
            <Toolbar style={{justifyContent: 'space-between'}}>
                <IconButton
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={handleMenuOpen}
                    edge="start"
                    size="large">
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6">
                    Личный кабинет учителя
                </Typography>

                <div>
                    <Button color="secondary" variant='contained' onClick={handleOpen}>Запланировать занятие</Button>
                </div>
            </Toolbar>
        </AppBar>
        <div>
            <Container>
                    <Routes>
                        <Route path={pathname} element={<Schedule />}/>
                        <Route  path={`${pathname}/access-time`} element={<AccessTime/>}/>
                        <Route  path={`${pathname}/lessons`} element={<Subjects />}/>
                        <Route  path={`${pathname}/learners`} element={<Learners />}/>
                        <Route  path={`${pathname}/material/:id`} element={<Materials />}/>
                    </Routes>
            </Container>
        </div>
        <Drawer open={openMenu} onClose={handleMenuClose}>
            <LeftMenu/>
        </Drawer>
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
        >
            <Fade in={open}>
                <div>
                    <CreateLessonForm close={handleClose} />
                </div>
            </Fade>
        </Modal>
    </>;
}
