import {CheckboxGroupProps, useCheckboxGroup} from "./CheckboxGroup";
import {Chip, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

export const ChipGroup = ({labels, values, setValue, isMulti, validValues}: CheckboxGroupProps) => {
    const {handleClick, isChecked, isValid} = useCheckboxGroup({values, setValue, isMulti, validValues})
    return (
        <Stack spacing={2} direction={'row'} flexWrap={'wrap'}>
            {labels.map((label) => (
                <Box key={label} mb={2}>
                    <Chip
                        label={label}
                        onClick={isValid(label) ? handleClick.bind(null, label) : undefined}
                        variant={isChecked(label) ? undefined : 'outlined'}
                        disabled={!isValid(label)}
                    />
                </Box>
            ))}
        </Stack>
    )
}
