export type FormValueType = {
    name: string,
    phone: string,
    subjects: string[],
    goals: string[],
    ageRange: string[],
}

export const initialValues: FormValueType = {
    name: '',
    phone: '',
    subjects: [],
    goals: [],
    ageRange: [],
};
const RU_SUBJ = 'Русский';
const FR_SUBJ = 'Французский';
export const subjects = [RU_SUBJ, FR_SUBJ];

enum Goal {
    ege = 'ЕГЭ',
    oge = 'ОГЭ',
    genDev = 'Общее развитие',
    other = 'Другое',
}

export const goals = [Goal.ege, Goal.oge, Goal.genDev, Goal.other];
export const ageRanges = ["4-10", "10-14", "14-16", "16-18", "18-27"];
export const VALID_COMBINATIONS = [
    ['4-10', FR_SUBJ, Goal.genDev],

    ['10-14', FR_SUBJ, Goal.genDev],
    ['10-14', FR_SUBJ, Goal.other],
    ['10-14', RU_SUBJ, Goal.genDev],
    ['10-14', RU_SUBJ, Goal.other],

    ['14-16', FR_SUBJ, Goal.genDev],
    ['14-16', FR_SUBJ, Goal.other],
    ['14-16', RU_SUBJ, Goal.oge],
    ['14-16', RU_SUBJ, Goal.ege],
    ['14-16', RU_SUBJ, Goal.genDev],
    ['14-16', RU_SUBJ, Goal.other],

    ['16-18', RU_SUBJ, Goal.oge],
    ['16-18', RU_SUBJ, Goal.ege],
    ['16-18', RU_SUBJ, Goal.genDev],
    ['16-18', RU_SUBJ, Goal.other],

    ['18-27', RU_SUBJ, Goal.ege],
    ['18-27', RU_SUBJ, Goal.genDev],
]
