import {Box, ButtonBase, Container, Fade, Grid, Modal, Stack, Typography,} from "@mui/material";
import {Login} from "../../components/Login/Login";
import React, {useState} from "react";
import {Reviews} from "./components/Reviews/Reviews";
import {ReactComponent as Logo} from '../../assets/logo.svg';
import {ReactComponent as Graduate} from '../../assets/graduate.svg';
import {ReactComponent as Avatar} from '../../assets/avatar.svg';
import {TelegramButton} from "./components/TelegramButton";
import {MailButton} from "./components/MailButton";
import {TailoredApproach} from "./components/TailoredApproach";
import {Title} from "./components/Title";
import {Request} from "./components/Request/Request";
import {Footer} from "../../components/Footer/Footer";
import {useHomeStyles} from "./Home.styles";

export const Home = () => {
    const classes = useHomeStyles();

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        // setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className={classes.root}>
                <div>
                    <Container maxWidth="lg" className={classes.container}>
                        <Stack direction='row' justifyContent={{xs: 'space-between', md: 'start'}} spacing={5}>
                            <Stack direction='row' justifyContent='center'>
                                <Logo/>
                                <Typography variant="h6" className={classes.title}>
                                    Шульгина <br/> Кристина
                                </Typography>
                            </Stack>
                            <ButtonBase className={classes.loginButton} onClick={handleOpen}>
                                <Stack direction='row' spacing={2} px={{md: 2}}>
                                    <Graduate/>
                                    <Typography variant="h6" className={classes.loginButtonName}>
                                        кабинет ученика
                                    </Typography>
                                </Stack>
                            </ButtonBase>
                        </Stack>
                    </Container>
                </div>
                <div>
                    <Container maxWidth="lg" className={classes.container}>
                        <Grid container spacing={0} component={Box} pb={'50px'}>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h1" className={classes.descriptionText}>
                                    Репетитор русского 🇷🇺 и французского 🇫🇷 языков
                                </Typography>
                            </Grid>
                            <Grid item xs={4} md={7}>
                                <Stack spacing={{xs: 1, md: 2}} direction={{xs: 'column', md: 'row'}} mt='29px'>
                                    <Box className={classes.infoBlock}>Русский язык <br/> для школьников<br/> с 4-го класса</Box>
                                    <Box className={classes.infoBlock1}>Подготовка к ОГЭ<br/> и ЕГЭ по
                                        русскому<br/> языку</Box>
                                    <Box className={classes.infoBlock2}>Обучение французскому языку учеников младших
                                        классов</Box>
                                </Stack>
                            </Grid>
                            <Grid item xs={8} md={5}>
                                <Box className={classes.avatarBlock}>
                                    <Avatar className={classes.avatar} height={300} width={260}/>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2} direction={{xs: "column", md: 'row'}} py={{md: 7}}>
                                    <ButtonBase className={classes.outlineButton} component={'a'} href='#about-me'>ОБ УЧИТЕЛЕ</ButtonBase>
                                    <ButtonBase className={classes.filledButton} component={'a'} href='#request'>ОСТАВИТЬ ЗАЯВКУ</ButtonBase>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6} my={4} justifyContent='center'
                                  className={classes.snButtonsContainer}>
                                <Box className={classes.snButtonsWrapper}>
                                    <Stack spacing={3} direction="row" justifyContent="center"
                                           className={classes.snButtons}>
                                        <TelegramButton/>
                                        <MailButton/>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <Container
                    maxWidth="lg"
                    className={classNames([classes.container, classes.contentBlock])}
                    style={{background: '#FFFBE6', zIndex: 2}}
                >
                    <TailoredApproach/>
                </Container>
                <Container className={classes.container2}>
                    <Grid container columnSpacing={{xs: 0, md: 2}}>
                        <Grid item md={6}>
                            <Box px={3} style={{background: '#FFEAF5'}} className={classes.contentBlock} id='about-me'>
                                <Box pt={'49px'}><Title>Обо мне</Title></Box>
                                <Typography mt={'30px'} className={classes.text}>
                                    Мне очень важны успехи моих учеников, поэтому я провожу контроль полученных знаний.
                                    При выявлении пробелов сразу их устраняю. Добиваюсь отличного усвоения материала.
                                    Всегда совершенствую свои навыки: имею степень магистра (МПГУ, Институт филологии.
                                    Русская филология, русский язык), регулярно прохожу курсы повышения квалификации,
                                    посещаю лекции и мастер-классы по педагогике и психологии. Все полученные знания
                                    сразу же применяю на практике. Считаю, что хороший преподаватель - это человек,
                                    который не просто научит новому, поможет добиться определенных результатов, но и
                                    изменит отношение к предмету и выработает привычку всегда стремиться к знаниям.
                                </Typography>
                                <Typography mt={2} className={classes.text}>
                                    Верю, что залог успешных занятий - это приятная атмосфера, дружеские отношения,
                                    поддержка ученика в моменты успеха и неудач, умение корректно исправлять ошибки,
                                    усердная работа, которая не воспринимается в тягость.
                                </Typography>
                                <Typography mt={2} className={classes.text}>
                                    Делаю свою работу ответственно и качественно. Для меня важно видеть прогресс
                                    ученика после каждого занятия. Составляю индивидуальную программу, подбираю
                                    индивидуальные домашние задания. Доступна в любое время для ученика и родителей
                                    по WhatsApp и Telegram по различным вопросам.
                                </Typography>
                                <Box pt={{xs: '40px', md: '60px'}}><Title>Образование</Title></Box>
                                <Box pt={2} pb={{xs: '24px', md: '60px'}}>
                                    <ul style={{paddingLeft: '19px'}}>
                                        <li>
                                            <Typography className={classes.text}>
                                                Бакалавриат. Московсковский государственный университет (МГУ)
                                                им. М.В. Ломоносова, 2013-2018 гг.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography mt={2} className={classes.text}>
                                                Профессиональная переподготовка. Московская международная академия
                                                (ММА),
                                                2018-2019 гг.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography mt={2} className={classes.text}>
                                                Магистратура (с отличием). Московский педагогический государственный университет
                                                (МПГУ),
                                                2020-2022 гг.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography mt={2} className={classes.text}>
                                                Повышение квалификации. АНО "НИИДПО" "Подготовка к ЕГЭ и ОГЭ по предмету "Русский язык": требования и технологии",
                                                108 часов с 13 июля 2022 г. по 4 августа 2022 г.
                                            </Typography>
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box px={3} style={{background: '#FFFFFF'}} className={classes.contentBlock}>
                                <Box pt={'49px'}><Title>Педагогическая деятельность</Title></Box>
                                <Box pt={2} pl={{xs: '19px', md: '0'}}>
                                    <ul style={{paddingLeft: 0}}>
                                        <li>
                                            <Typography className={classes.text}>
                                                Преподавание русского и французского языков. Сеть образовательных
                                                центров "АкадемКлуб", 2017-2020 гг.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography mt={2} className={classes.text}>
                                                Работа методистом. Сеть образовательных центров "АкадемКлуб", 2019-2020
                                                гг.
                                            </Typography>
                                        </li>
                                        <li>
                                          <Typography mt={2} className={classes.text}>
                                            Частный преподаватель с 2013 г.
                                          </Typography>
                                        </li>
                                    </ul>
                                </Box>
                                <Box pt={{xs: '40px', md: '60px'}}><Title>Научная деятельность</Title></Box>
                                <Box pt={2} pl={{xs: '19px', md: '0'}}>
                                    <ul style={{paddingLeft: 0}}>
                                        <li>
                                            <Typography className={classes.text}>
                                                Участие в международной научной конференции "Язык и поэтика текста".
                                                МПГУ, 22.01.2021.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography mt={2} className={classes.text}>
                                                Участие в международной научной конференции "Научная парадигма
                                                современной русистики". МПГУ, 24.09.2021.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography mt={2} className={classes.text}>
                                                Участие во всероссийской научной конференции "Лексикология и грамматика
                                                русского языка и стилистика художественного текста". МГОУ, 17.12.2021.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography mt={2} className={classes.text}>
                                                Участие в международной научной конференции "Язык и поэтика текста".
                                                МПГУ, 21.01.2022.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography mt={2} className={classes.text}>
                                                Публикация ВАК в общероссийском научном журнале "Преподаватель XXI век" 2 / 2022.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography mt={2} className={classes.text}>
                                                Публикация в сборнике научных статей "Актуальные проблемы русистики и
                                                поэтики текста", 2022 г.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography mt={2} className={classes.text}>
                                                Публикация в международном научном журнале "Молодой учёный",
                                                январь 2022 г.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography mt={2} className={classes.text}>
                                                Участие в научно-практической конференции студентов и аспирантов "А ты
                                                посадил своё дерево?! Историко-словообразовательный словарь "Русский
                                                Древослов" как феномен науки, культуры и образования". МПГУ, 29.06.2022.
                                            </Typography>
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container className={classes.container2}>
                    <Request className={classes.contentBlock}/>
                </Container>
                <div style={{maxWidth: '100vw', overflow: 'hidden'}}>
                    <Reviews/>
                </div>
            </div>
            <Footer />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
            >
                <Fade in={open}>
                    <div>
                        <Login/>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}

function classNames(classes: string[]): string | undefined {
    return classes.join(' ');
}

