import React from "react"
import {Box, Grid, Stack, Typography} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { ReactComponent as Estimate } from './assets/estimate.svg';
import { ReactComponent as Star } from './assets/star.svg';
import { ReactComponent as Crown } from './assets/crown.svg';
import { ReactComponent as Book } from './assets/book.svg';
import { ReactComponent as Pencil } from './assets/pencil.svg';
import { ReactComponent as Check } from './assets/check.svg';
import { ReactComponent as Reviewer } from './assets/reviewer.svg';
import {Title} from "./Title";


const useStyles = makeStyles(() =>
    createStyles({
        row: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            width: '48px',
            minWidth: '48px',
            height: '48px',
            marginRight: '15px',
        },
        text: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '110%',
        }
    })
)

export const TailoredApproach = () => {
    const classes = useStyles();
    return <div>
        <Grid container spacing={0} component={Box} my={{xs: 4, md: 6}}>
            <Grid item md={6}><Title> Индивидуальный подход </Title></Grid>
            <Grid item md={6} mt={{md:0, xs: 4}}>
                <Box>
                    <Stack spacing={2} direction="column">
                        <Box className={classes.row}>
                            <Estimate className={classes.icon}/>
                            <Typography className={classes.text}>Оценка знаний</Typography>
                        </Box>
                        <Box className={classes.row}>
                            <Star className={classes.icon}/>
                            <Typography className={classes.text}>Определение целей обучения</Typography>
                        </Box>
                        <Box className={classes.row}>
                            <Crown className={classes.icon}/>
                            <Typography className={classes.text}>Разработка индивидуальной программы</Typography>
                        </Box>
                        <Box className={classes.row}>
                            <Book className={classes.icon}/>
                            <Typography className={classes.text}>Подбор учебных материалов</Typography>
                        </Box>
                        <Box className={classes.row}>
                            <Pencil className={classes.icon}/>
                            <Typography className={classes.text}>Составление индивидуальных домашних заданий</Typography>
                        </Box>
                        <Box className={classes.row}>
                            <Check className={classes.icon}/>
                            <Typography className={classes.text}>Проведение проверочных работ и корректировка программы</Typography>
                        </Box>
                        <Box className={classes.row}>
                            <Reviewer className={classes.icon}/>
                            <Typography className={classes.text}>Предоставление обратной связи родителям</Typography>
                        </Box>
                    </Stack>
                </Box>
            </Grid>
        </Grid>
    </div>
}
