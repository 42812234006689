import {Alert, Button, Checkbox, FormControlLabel, FormGroup, Grid, Stack, TextField, Typography} from "@mui/material";
import React, {useMemo, useState} from "react";
import Box from "@mui/material/Box";
import {useFormik} from "formik";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {ApolloError} from "@apollo/client";
import {CheckboxGroup} from "./CheckboxGroup";
import {ChipGroup} from "./ChipGroup";
import {ageRanges, FormValueType, goals, initialValues, subjects, VALID_COMBINATIONS} from "./Request.constants";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '32px',
            lineHeight: '101.9%',
            [theme.breakpoints.up('md')]: {
                fontSize: '80px',
            },
        },
        subTitle: {
            fontFamily: 'Montserrat',
            fontWeight: 800,
            fontSize: '20px',
            lineHeight: '26px',
        },
        input: {
            [theme.breakpoints.down('md')]: {
                width: '100%',
            }
        },
        sendButton: {
            width: '287px',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            }
        },
    })
);

export const useValidValues = (...values: string[][]): string[][] => useMemo(() => {
    const result = [];
    for (let i = 0; i < values.length; i++) {
        let columnValues = new Set(VALID_COMBINATIONS.map(row => row[i]));
        for (let j = 0; j < values.length; j++) {
            if (i === j || !values[j].length) continue;
            Array.from(columnValues).forEach(column => {
                const isValid = values[j].every(value => VALID_COMBINATIONS.some(row => row[i] === column && row[j] === value));
                if (!isValid) {
                    columnValues.delete(column);
                }
            });
        }
        result[i] = Array.from(columnValues);
    }
    return result;
}, [...values]);

export function RequestForm({disabled, error, onSubmit}: {
    onSubmit: any, error?: ApolloError, disabled: boolean
}) {
    const classes = useStyles();
    const formikParams = useMemo(() => ({initialValues, onSubmit}), [onSubmit])
    const formik = useFormik<FormValueType>(formikParams);
    const [validAgeRanges,validSubjects,validGoals] = useValidValues(formik.values.ageRange, formik.values.subjects, formik.values.goals)
    const [isAgree, setAgreement] = useState(false);

    return (
        <Grid component={"form"} onSubmit={formik.handleSubmit} container spacing={3}>
            <Grid item md={6}>
                <Box mt="30px">
                    <Typography className={classes.subTitle}>Ваши данные</Typography>
                    <Box mt="20px">
                        <Stack direction={{xs: "column", md: "row"}} spacing={3}>
                            <Box>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Имя"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    className={classes.input}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Телефон"
                                    name="phone"
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    className={classes.input}
                                />
                            </Box>
                        </Stack>

                    </Box>
                </Box>
                <Box mt="30px">
                    <Typography className={classes.subTitle}>Цель обучения</Typography>
                    <Box mt="20px">
                        <ChipGroup
                            labels={goals}
                            values={formik.values.goals}
                            setValue={formik.setFieldValue.bind(null, "goals")}
                            validValues={validGoals}
                            isMulti
                        />
                    </Box>

                </Box>
            </Grid>
            <Grid item md={6}>
                <Box mt={{md: "30px"}}>
                    <Typography className={classes.subTitle}>Язык</Typography>
                    <Box mt={{xs: "10px", md: 0}}>
                        <CheckboxGroup
                            labels={subjects}
                            values={formik.values.subjects}
                            setValue={formik.setFieldValue.bind(null, "subjects")}
                            validValues={validSubjects}
                            isMulti
                        />
                    </Box>
                </Box>
                <Box mt="20px">
                    <Typography className={classes.subTitle}>Возраст ученика</Typography>
                    <Box mt="20px">
                        <ChipGroup
                            labels={ageRanges}
                            values={formik.values.ageRange}
                            validValues={validAgeRanges}
                            setValue={formik.setFieldValue.bind(null, "ageRange")}
                        />
                    </Box>
                </Box>
            </Grid>
            {!!error && (
                <Grid item xs={12}>
                    <Alert severity="error">
                        <Typography component={"p"} fontWeight={"bold"}>При отправке формы возникли ошибки:</Typography>
                        {error.graphQLErrors.map(e => (
                            <Typography component={'p'} key={e.message}>{e.message}</Typography>
                        ))}
                    </Alert>
                </Grid>
            )}
            <Grid item md={6}>
                <Box>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={isAgree} onClick={() => setAgreement(v=>!v)}/>}
                            label="Я принимаю согласие на обработку персональных данных"
                        />
                    </FormGroup>
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box>
                    <Button
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.sendButton}
                        disabled={disabled || !isAgree}
                    >
                        Отправить
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}
